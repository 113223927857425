import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Icon from '../icon/Icon'
import AuthContext from '../../contexts/authContext'
import { TableLoader, showLoader } from '../../services/loader.services'
import NoDataMsg from '../../common/components/NoDataMsg'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { toasts } from '../../services/toaste.service'
import { getLicenseKey } from '../../services/application.settings'
import FormGroup from '../bootstrap/forms/FormGroup'
import Select from '../bootstrap/forms/Select'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas'
import AlertService from '../../services/alert.service'
import { addDispatchProduct, getDispatchProduct, getProductDetails, getProductDetailsCount } from '../../services/productService'
import SearchableSelect from '../../common/components/SearchableSelect'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal'

const DispatchProduct = () => {

    useEffect(() => {
        getLotDetailsList()
        getDispatchProductList()
    }, [])

    const dispatchProductForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyName: '',
        },
        validate: (values: any) => {
            const errors: {
                companyName?: string;
            } = {};
            if (!values.companyName) {
                errors.companyName = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addDispatchProductSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [orderReceivedDetailsData, setOrderReceivedDetailsData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(orderReceivedDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [dataStatus, setDataStatus] = useState(false)

    const [isOpen, setIsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [lotNo, setLotNo] = useState<any>('')

    const [lotData, setLotData] = useState([])
    const [countData, setCountData] = useState([])

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            // i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            // i.countNo !== null && i.countNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.companyName !== null && i.companyName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
        // i.noOfBale !== null && i.noOfBale.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );


    function setDispatchProductDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            companyName: dispatchProductForm.values.companyName,
            dispatchDetails: dispatchDetails
        })
    }

    function addDispatchProductSubmit() {
        showLoader(true)
        let dispatchProductPostData = setDispatchProductDetails()
        if (dispatchDetails.length > 0) {
            if (dispatchProductForm.isValid) {
                addDispatchProduct(dispatchProductPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            closeAndReset()
                        }
                        else if (data.success == false) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false)
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (dispatchProductForm.isValid == false) {
                toasts("Please fill all details", "Error");
            }
        }
        else {
            toasts("Please Enter Dispatch Details", "Error");
        }
    }

    function getDispatchProductList() {
        getDispatchProduct(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dispatchProduct;
                    if (data != undefined) {
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {

                            let dispatchDetailsLength = data[i].dispatchProductDetails

                            for (let j = 0; j < dispatchDetailsLength.length; j++) {
                                dispatchDetailsLength[j].sno = j + 1;
                                dispatchDetailsLength[j].companyName = data[i].companyName;

                            }
                        }
                        setOrderReceivedDetailsData(data)

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getLotDetailsList() {
        getProductDetailsCount(0, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productDetailsCount;
                    if (data != undefined) {
                        setLotData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCountDetails(lotId: any) {
        getProductDetailsCount(lotId, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productDetailsCount;
                    if (data != undefined) {
                        setCountData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        dispatchProductForm.resetForm()
        setLotNo('')
        getDispatchProductList()
        setAssignCompanyToCone(false)
        setIsOpenModal(false)
        setInputList([])
    }

    const [assignCompanyToCone, setAssignCompanyToCone] = useState(false)
    const [dispatchDetails, setDispatchDetails] = useState<any>([])
    const [inputList, setInputList] = useState<any>([])
    const [count, setCount] = useState<any>(2)

    function add() {
        for (let i = 0; i < count; i++) {
            setCount(count + 1)
        }
        setInputList([...inputList, { displayOrder: count, productDetailsCountId: '', noOfBale: '' }])
    }

    function handleremove(displayOrder: any, index: any) {
        const list = [...inputList];
        list.splice(index, 1)
        setInputList(list)
        let selectedItem
        dispatchDetails.forEach((item: any) => {
            if (item['displayOrder'] == displayOrder) {
                selectedItem = item
                dispatchDetails.splice(dispatchDetails.indexOf(selectedItem), 1);
            }
        });
    }

    const [productionEntryData, setProductionEntryData] = useState([])
    const [isDupliteEntry, setIsDupliteEntry] = useState(false)

    function handleInputChange(lotId: any, productDetailsCountId: any, noOfBale: any, index: any) {

        if (lotId?.value > 0) {
            getCountDetails(lotId?.value)
        }

        const list = [...inputList];

        let existingRoll = inputList.filter((data: any) => (data.productDetailsCountId == productDetailsCountId?.value && data.displayOrder != list[index]['displayOrder']))

        setIsDupliteEntry(existingRoll != '' ? true : false)

        list[index]['lotId'] = lotId?.value
        list[index]['productDetailsCountId'] = productDetailsCountId?.value
        list[index]['noOfBale'] = noOfBale
        list[index]['lotData'] = lotId
        list[index]['countData'] = productDetailsCountId
        list[index]['existingRoll'] = existingRoll != '' ? true : false

        setInputList(list);
        setDispatchDetails(list);
    }

    function openModal() {
        setIsOpenModal(true)
        setInputList([...inputList, { displayOrder: 1, productDetailsCountId: '', noOfBale: '' }])
    }

    const [isOpenModal, setIsOpenModal] = useState(false)

    return (
        <PageWrapper title={`Dispatch`}>
            <Page>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-7">
                            <CardTitle tag='div' className='h5'>Dispatch - Product</CardTitle>
                        </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Button color='primary' icon='Add' isLight onClick={openModal}>
                                Assign
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('lotNo')} className='cursor-pointer text-decoration-underline'>Lot No <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('countNo')} className='cursor-pointer text-decoration-underline'>Count No <Icon size='lg' className={getClassNamesFor('countNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('companyName')} className='cursor-pointer text-decoration-underline'>Company Name <Icon size='lg' className={getClassNamesFor('companyName')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('noOfBale')} className='cursor-pointer text-decoration-underline'>No Of Bale <Icon size='lg' className={getClassNamesFor('noOfBale')} icon='FilterList' /></th>
                                    </tr>
                                </thead>
                                {orderReceivedDetailsData != "" ? <>
                                    {filteredData.map((data: any) => ( <>
                                        {data.dispatchProductDetails.map((item: any) => (
                                        <tbody key={item.productDetailsCountId}>
                                            <tr>
                                                <td>{item.sno}</td>
                                                <td>{item.lotNo}</td>
                                                <td>{item.countNo}</td>
                                                <td>{item.companyName}</td>
                                                <td>{item.noOfBale}</td>
                                            </tr>
                                        </tbody>
                                    ))} </> ))} </> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>

                <Modal
                    setIsOpen={closeAndReset}
                    isOpen={isOpenModal}
                    titleId='edit'
                    isStaticBackdrop
                    isCentered
                    size='lg'>
                    <ModalHeader setIsOpen={closeAndReset}>
                        <ModalTitle id='examplemailcontent'>
                            <div className="h4">Dispatch - Assign Bale Count
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row g-4'>
                            <div className='col-6'>
                                <FormGroup id='companyName' label='Company Name' isFloating>
                                    <Input onChange={dispatchProductForm.handleChange} value={dispatchProductForm.values.companyName}
                                        isValid={dispatchProductForm.isValid}
                                        onBlur={dispatchProductForm.handleBlur}
                                        isTouched={dispatchProductForm.touched.companyName}
                                        invalidFeedback={dispatchProductForm.errors.companyName}
                                        placeholder="Enter Company Name" />
                                </FormGroup>
                            </div>
                            <div className='col-md-6'>
                                <FormGroup className='d-flex justify-content-end'>
                                    <Button className="mt-4" icon="Add" color='info' size={'sm'} onClick={add}>
                                        Add
                                    </Button>
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                <table className='table table-modern table-hover '>
                                    <thead>
                                        <tr className='table-primary text-center'>
                                            <th style={{ width: '20%' }}>Lot No</th>
                                            <th style={{ width: '20%' }}>Count No</th>
                                            <th style={{ width: '20%' }}>No Of Bale</th>
                                            {inputList.length > 1 ?
                                                <th style={{ width: '3%' }}></th> : null
                                            }
                                        </tr>
                                    </thead>
                                    {inputList.map((x: any, i: any) => (
                                        <tbody key={i}>
                                            <tr>
                                                <td>
                                                    <FormGroup id='lotId' label='Lot No' isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel={''}
                                                            placeholder='Lot No'
                                                            className="form-control"
                                                            value={x.lotData}
                                                            isValid={dispatchProductForm.isValid}
                                                            onBlur={dispatchProductForm.handleBlur}
                                                            list={lotData.map((data: any) => (
                                                                { value: data.lotId, label: data.lotNo }
                                                            ))}
                                                            onChange={(e: any) => handleInputChange(e, x.countData, x.noOfBale, i)} />
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <FormGroup id='countNo' label='Count No' isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel={''}
                                                            placeholder='Count No'
                                                            className="form-control"
                                                            value={x.countData}
                                                            onBlur={dispatchProductForm.handleBlur}
                                                            list={countData.map((data: any) => (
                                                                { value: data.productDetailsCountId, label: data.countNo }
                                                            ))}
                                                            onChange={(e: any) => handleInputChange(x.lotData, e, x.noOfBale, i)} />
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <FormGroup id='noOfBale' label='No Of Bale' isFloating>
                                                        <Input
                                                            onChange={(e: any) => handleInputChange(x.lotData, x.countData, e.target.value, i)}
                                                            value={x.noOfBale}
                                                            isValid={dispatchProductForm.isValid}
                                                            onBlur={dispatchProductForm.handleBlur}
                                                            type='number'
                                                            placeholder="Enter No Of Bale" />
                                                    </FormGroup>
                                                </td>
                                                {inputList.length > 1 ?
                                                    <td>
                                                        <Button className='mt-2' color='danger' isLight icon='Close'
                                                            onClick={() => handleremove(x.displayOrder, i)}
                                                        />
                                                    </td> : null
                                                }
                                            </tr>
                                            {x.existingRoll ?
                                                <tr className='text-center'><td className='text-danger' colSpan={5}>Duplite Entry</td></tr> : null
                                            }
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='info'
                            isOutline
                            className='border-0'
                            onClick={closeAndReset}>
                            Close
                        </Button>
                        <Button color='info' type="submit" icon="Save" isDisable={isDupliteEntry} onClick={dispatchProductForm.handleSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default DispatchProduct