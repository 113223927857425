import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Icon from '../icon/Icon'
import AuthContext from '../../contexts/authContext'
import { TableLoader } from '../../services/loader.services'
import NoDataMsg from '../../common/components/NoDataMsg'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { toasts } from '../../services/toaste.service'
import { getProductDetails } from '../../services/productService'

const ProductList = () => {

    useEffect(() => {
        getDispatchProductList()
    }, [])

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [productDetailsData, setProductDetailsData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(productDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [dataStatus, setDataStatus] = useState(false)

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.countNo !== null && i.countNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.blend !== null && i.blend.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.grossWeight !== null && i.grossWeight.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.tareWeight !== null && i.tareWeight.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.netWeight !== null && i.netWeight.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.month !== null && i.month.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.year !== null && i.year.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.bagNo !== null && i.bagNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.exNo !== null && i.exNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function getDispatchProductList() {
        getProductDetails(0,0,0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productDetails;
                    if (data != undefined) {
                        setProductDetailsData(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedData = i
            window.open(selectedData.pdfPath);
        }
    }

    return (
        <PageWrapper title={`Dispatch`}>
            <Page>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-7">
                            <CardTitle tag='div' className='h5'>Product List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                        </ButtonGroup>                        
                    </CardHeader>
                    <CardBody>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('lotNo')} className='cursor-pointer text-decoration-underline'>Lot No <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('countNo')} className='cursor-pointer text-decoration-underline'>Count No <Icon size='lg' className={getClassNamesFor('countNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('blend')} className='cursor-pointer text-decoration-underline'>Blend Name <Icon size='lg' className={getClassNamesFor('blend')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('grossWeight')} className='cursor-pointer text-decoration-underline'>Gross Weight <Icon size='lg' className={getClassNamesFor('grossWeight')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('tareWeight')} className='cursor-pointer text-decoration-underline'>Tare Weight <Icon size='lg' className={getClassNamesFor('tareWeight')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('netWeight')} className='cursor-pointer text-decoration-underline'>Net Weight <Icon size='lg' className={getClassNamesFor('netWeight')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('bagNo')} className='cursor-pointer text-decoration-underline'>Bag No <Icon size='lg' className={getClassNamesFor('bagNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('exNo')} className='cursor-pointer text-decoration-underline'>Ex No <Icon size='lg' className={getClassNamesFor('exNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('month')} className='cursor-pointer text-decoration-underline'>Month <Icon size='lg' className={getClassNamesFor('month')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('year')} className='cursor-pointer text-decoration-underline'>Year <Icon size='lg' className={getClassNamesFor('year')} icon='FilterList' /></th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {productDetailsData != "" ? <>{filteredData.map((item: any) => (<tbody key={item.productDetailsId}><tr><td>{item.sno}</td><td>{item.lotNo}</td><td>{item.countNo}</td><td>{item.blend}</td><td>{item.grossWeight}</td><td>{item.tareWeight}</td><td>{item.netWeight}</td><td>{item.bagNo}</td><td>{item.exNo}</td><td>{item.month}</td><td>{item.year}</td><td className="d-print-none"><Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(item)}></Button></td></tr></tbody>))}</> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>                
            </Page>
        </PageWrapper >
    )
}

export default ProductList