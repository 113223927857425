import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getProductDetails = (productDetailsId: any, lotId: any, isDropdown: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProductDetails/${getLicenseKey}/${productDetailsId}/${lotId}/${isDropdown}`,
        response => {
            success(response)
        },
        error => {
            failure("getProductDetails - " + error.message)
            console.log('error caught in service : getProductDetails')
        },
    );

export const addProductDetails = (productDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addProductDetails`, productDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addProductDetails')
        }
    );

    
export const getProductDetailsCount = (lotId: any, isDropdown: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProductDetailsCount/${getLicenseKey}/${lotId}/${isDropdown}`,
        response => {
            success(response)
        },
        error => {
            failure("getProductDetailsCount - " + error.message)
            console.log('error caught in service : getProductDetailsCount')
        },
    );

export const getDispatchProduct = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDispatchProduct/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDispatchProduct - " + error.message)
            console.log('error caught in service : getDispatchProduct')
        },
    );

export const addDispatchProduct = (dispatchProductPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addDispatchProduct`, dispatchProductPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addDispatchProduct')
        }
    );

export const getChallanDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getChallanDetails/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getChallanDetails - " + error.message)
            console.log('error caught in service : getChallanDetails')
        },
    );